import { checkVAT, countries } from 'jsvat-next';

export const vatNumberValidator = {
  isValid(vatNumber: string): boolean {
    return checkVAT(vatNumber, countries).isValid;
  },
  convertBusinessIdToVatNumber(businessId: string): string {
    return `FI${businessId.replace('-', '')}`;
  },
  vatNumberAndCountryMatch(vatNumber: string, country: string | undefined): boolean {
    if (!country) {
      return false;
    }
    return checkVAT(vatNumber, countries).country?.isoCode.short === country;
  },
};
